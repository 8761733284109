@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/opensans/subset-OpenSans-Regular.eot');
	src: url('/fonts/opensans/subset-OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/opensans/subset-OpenSans-Regular.woff2') format('woff2'),
	url('/fonts/opensans/subset-OpenSans-Regular.woff') format('woff'),
	url('/fonts/opensans/subset-OpenSans-Regular.ttf') format('truetype'),
	url('/fonts/opensans/subset-OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/opensans/subset-OpenSans-SemiBold.eot');
	src: url('/fonts/opensans/subset-OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/opensans/subset-OpenSans-SemiBold.woff2') format('woff2'),
	url('/fonts/opensans/subset-OpenSans-SemiBold.woff') format('woff'),
	url('/fonts/opensans/subset-OpenSans-SemiBold.ttf') format('truetype'),
	url('/fonts/opensans/subset-OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/fonts/opensans/subset-OpenSans-Bold.eot');
	src: url('/fonts/opensans/subset-OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/opensans/subset-OpenSans-Bold.woff2') format('woff2'),
	url('/fonts/opensans/subset-OpenSans-Bold.woff') format('woff'),
	url('/fonts/opensans/subset-OpenSans-Bold.ttf') format('truetype'),
	url('/fonts/opensans/subset-OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/fonts/rubik/subset-Rubik-Regular.eot');
	src: url('/fonts/rubik/subset-Rubik-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/rubik/subset-Rubik-Regular.woff') format('woff'),
	url('/fonts/rubik/subset-Rubik-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/fonts/rubik/subset-Rubik-Medium.eot');
	src: url('/fonts/rubik/subset-Rubik-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/rubik/subset-Rubik-Medium.woff2') format('woff2'),
	url('/fonts/rubik/subset-Rubik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
