.paging {
	padding: 15px 0;
}

.pagination {
	display: inline-block;
	margin: 0;
	border-radius: $border-radius-base;
	padding: 0;
	> li {
		display: inline; // Remove list-style and block-level defaults
		> a,
		> span {
			position: relative;
			float: left; // Collapse white-space
			margin-right: 8px;
			line-height: 32px;
			width: 40px;
			height: 34px;
			text-decoration: none;
			text-align: center;
			color: $pagination-color;
			// background-color: $pagination-bg;
			//border: 1px solid $border-color;
			//font-weight: 600;
			font-size: $font-size-base;
		}
		&:first-child {
			> a,
			> span {
				margin-left: 0;
				//@include border-left-radius($border-radius-base);
			}
		}
		&:last-child {
			> a,
			> span {
				//@include border-right-radius($border-radius-base);
			}
		}
	}

	> li > a,
	> li > span {
		&:hover,
		&:focus {
			color: $pagination-hover-color;
			//background-color: $pagination-hover-bg;
			//border-color: $pagination-hover-border;
			text-decoration: underline;
		}
	}

	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			color: $pagination-active-color;
			text-decoration: underline;
			background-color: $pagination-active-bg;
			border-color: $pagination-active-border;
			cursor: default;
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			color: $pagination-disabled-color;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border;
			cursor: $cursor-disabled;
		}
	}
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
	@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
	@include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
