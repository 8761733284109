body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	margin-top: 7px;
	input.form-control {
		background: $wh;
		border-radius: 0;
		border: 1px solid $wh;
		border-right: none;
		color: $bk;
		@include placeholder($bc);
	}
	.input-group-btn {
		background: $tc;
		text-align: center;
		width: 36px;
		@include transition(all 0.2s ease-in-out);
		&:hover {
			background: $dg;
		}
	}
	.btn-search {
		display: inline-block;
		background: transparent;
		font-size: 18px !important;
		cursor: pointer;
		color: $wh;
		border: 1px solid $wh;
	}
}

.form-control::-moz-placeholder {
	color: $bc;
}

// module cart
#cart {
	height: 100%;
	display: inline-block;
	width: auto;
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
		text-decoration: none;
	}
	.icon-cart {
		background: transparent;
		display: block;
		position: relative;
		text-align: center;
		padding: 0 15px;
		height: 31px;
		i {
			font-size: 36px;
			line-height: 28px;
		}
	}
	.wrap-cart {
		color: $bk;
		font-size: 13px;
		span {
			position: relative;
		}
		.badge {
			position: absolute !important;
			top: 8px !important;
			right: 13px;
			padding: 3px;
			min-width: 17px;
			line-height: 11px;
			z-index: 99;
			background: $tc;
		}
	}
	.price-cart {
		color: $tc;
	}
	.cart-inner {
		text-align: right;
	}
}

.product-compare-header {
	position: relative;
	display: inline-block;
	width: auto;
	height: 100%;
	z-index: 999;
	text-align: center;
	cursor: pointer;
	a {
		color: $bk;
		div {
			display: inline-block;
			padding-left: 5px;
		}
	}
	p {
		margin: 0;
		&#compare-total {
			line-height: 19px;
			font-size: 13px;
		}
	}
	i {
		font-size: 32px;
		line-height: 31px;
		display: block;
	}
	.badge {
		color: $white;
		position: absolute !important;
		top: 8px !important;
		right: 10px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $tc;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include border-radius($border-color, 3px)
		}
	}
}